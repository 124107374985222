<template>
  <div class="p-0 m-0">
    <Sidebar />

    <StoreImage :store_detail="detailStore" />

    <div class="d-flex flex-row justify-space-between align-center" style="margin: 0px 10px 0px 8px">
      <UserName />

      <div>
        <v-btn
          @click.prevent="$router.push('/carts')"
          depressed
          small
          color="#E93B81"
          height="30"
        >
          <span style="color: white">{{ $t("message.to-cart") }}</span>
        </v-btn>
      </div>
    </div>

    <div style="margin-bottom: 7em;">
      <HeaderNavigasi :link="links" />

      <div v-if="isLoading" class="text-center">
        <div v-for="i in 7" :key="i">
          <SkeletonProduct />
        </div>
      </div>

      <div v-else>
        <div
          v-if="listItemFavByStore.length == 0"
          class="d-flex flex-row align-center justify-center mt-12"
        >
          <h3 style="color: grey">
            {{ $t("message.no-have-product-favorite-store") }}
          </h3>
        </div>

        <div>
          <v-card
            v-for="list in listItemFavByStore"
            :key="list.id"
            class="odd_even mb-3"
            flat
            @click="toDetail(list.slug)"
          >
            <ListProduct :data="list" />
          </v-card>
        </div>
      </div>
      <div class="mt-5">
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
  </div>
</template>

<script>
import LoadingPage from "../../components/LoadingPage.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import CategoryFooter from "../../components/CategoryFooter.vue";
import ListProduct from "../../components/ListProduct.vue";
import StoreImage from "../../components/StoreImage.vue";
import User from "../../components/User.vue";
import SkeletonProduct from "../../components/skeleton/SkeletonProduct.vue";
import Sidebar from "../../components/Sidebar.vue";
import Cashier from "../../components/Cashier.vue";
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
export default {
  name: "ShopFavorites",
  components: {
    Sidebar,
    UserName: User,
    Cashier,
    StoreImage,
    ListProduct,
    CategoryFooter,
    HeaderNavigasi,
    LoadingPage,
    SkeletonProduct,
  },
  data() {
    return {
      idStore: "",
      pathRoute: "",
      isLoading: false,
    };
  },
  methods: {
    async dispatchStore() {
      this.idStore = this.$router.history.current.params.id;
      // let id = this.$router.history.current.params.id
      let data = await this.$store.dispatch("shop_module/fetchStore", {
        id: this.idStore,
      });
      return data;
    },
    async itemFavoriteByStore() {
      this.isLoading = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "itemFavorite_module/itemFavoriteByStore",
        id
      );
      this.isLoading = false;
      return data;
    },
    toDetail(slug) {
      this.$router.push("/items/" + slug);
    },
  },
  computed: {
    detailStore() {
      return this.$store.state.shop_module.store;
    },
    listItemFavByStore() {
      return this.$store.state.itemFavorite_module.itemFavByStore;
    },
    links() {
      return [
        {
          text: this.$t("message.route-search"),
          route: `/store/${this.idStore}/category`,
          icon: mdiMagnify,
        },
        {
          text: this.$t("message.route-favorite"),
          route: `/store/${this.idStore}/wishlist`,
          icon: mdiHeartOutline,
        },
        {
          text: this.$t("message.route-history"),
          route: `/store/${this.idStore}/purchase-history`,
          icon: mdiCart,
        },
        {
          text: this.$t("message.route-store"),
          route: "/store",
          icon: mdiStore,
        },
      ];
    },
  },
  created() {
    this.dispatchStore();
    this.itemFavoriteByStore();
  },
};
</script>

<style>
.class-tulisan {
  position: absolute;
  left: 15%;
  top: 9%;
  /* background-color: black; */
}
.detail-img {
  opacity: 0.9;
  border: 1px solid black;
  margin-top: 25px;
  width: 100%;
  height: 125px;
  margin-bottom: 10px;
  position: relative;
  background-color: black;
}
</style>
